import { BookOutlined, CheckSquareOutlined, ContainerOutlined, LockOutlined } from "@ant-design/icons"
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  message,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Statistic,
  Tag,
  Typography,
} from "antd"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { isModuleLocked } from "../../../helpers/isModuleLocked"
import { useEnrolmentMutation } from "../../../network/useEnrolment"
import { fetchLesson } from "../../../network/useLearning"
import { useGetModule } from "../../../network/useModule"
import { useGetPerson } from "../../../network/usePerson"
dayjs.extend(relativeTime)
dayjs.extend(duration)

export function CourseModuleModal({ courseUid, moduleUid, courseQuery }) {
  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()

  const { replace, push } = useHistory()
  const queryClient = useQueryClient()
  const personQuery = useGetPerson({ authenticated: true })
  const enrolmentMutation = useEnrolmentMutation(courseUid)

  const moduleQuery = useGetModule({
    enrolmentUid: courseUid,
    moduleUid,
  })

  useEffect(() => {
    if (!moduleQuery.isLoading) {
      if (!moduleQuery.data) {
        message.error("Sorry, we could not find the module you were looking for")
        setTimeout(() => replace(`/courses/${courseUid}`), 500)
      } else {
        queryClient.prefetchQuery(
          [courseUid, "lesson", moduleQuery?.data?.learning?.[0]?.uid],
          () => fetchLesson(courseUid, moduleQuery?.data?.learning?.[0]?.uid, queryClient),
          {
            staleTime: 1000 * 60 * 5,
          }
        )
      }
    }
  }, [moduleQuery.isLoading, moduleQuery.data])

  const handleClick = async (href) => {
    if (courseQuery?.data?.currentState !== "not-started") push(href)
    else {
      setLoading(true)
      notification.success({
        message: `${t("Hey")}, ${personQuery.data?.firstName}!`,
        description: `${t("thankYouForStartingThe")} ${courseQuery.data?.publication?.translations["1"]?.title} ${
          courseQuery.data?.publication?.type === "course" ? t("course") : ""
        }, ${t("weHopeYourEnjoyIt")}.`,
      })
      const res = await enrolmentMutation.mutateAsync(
        {
          enrolmentUid: courseUid,
          data: {
            currentState: "in-progress",
            startedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
          },
        },
        {
          onSettled: () => {
            setLoading(false)
          },
        }
      )

      if (res.data?.length) {
        queryClient.invalidateQueries(courseUid)
      }
      push(href)
    }
  }

  const duration = dayjs
    .duration(courseQuery?.data?.publication?.material?.find((x) => x?.uid === moduleUid)?.duration)
    .humanize()

  return (
    <Modal
      visible={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className="modal modal--noPadding"
      onCancel={() => {
        setVisible(false)
        setTimeout(() => replace(`/courses/${courseUid}`), 500)
      }}
    >
      {!moduleQuery.isLoading && moduleQuery.data ? (
        <>
          <div
            className="modal__image"
            style={{
              backgroundImage: moduleQuery?.data?.featureImageUrl
                ? `url('${moduleQuery?.data?.featureImageUrl}')`
                : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
            }}
          />
          <div className="modal__body">
            <Space style={{ marginBottom: "0.625rem" }}>
              {moduleQuery?.data?.activity?.some((x) => x?.uid === moduleUid && x?.status === "completed") && (
                <Tag color="green">{t("Completed")}</Tag>
              )}
            </Space>
            <Typography.Title level={2}>{moduleQuery?.data?.title}</Typography.Title>
            <Typography.Paragraph className="desc">
              <div
                dangerouslySetInnerHTML={{
                  __html: moduleQuery?.data?.descriptionAsHTML,
                }}
              />
            </Typography.Paragraph>

            <Space style={{ width: "100%", marginBottom: "2.5rem" }}>
              <Badge
                color="green"
                text={
                  <span className="badge--meta">
                    {moduleQuery.data.dueDate ? dayjs(moduleQuery.data.dueDate).format("DD/MM/YYYY") : t("noDueDate")}
                  </span>
                }
              />
              <Badge
                color="purple"
                text={<span className="badge--meta">{duration.includes("NaN") || !duration ? "" : duration}</span>}
              />
              {/* <Badge
                color="purple"
                text={
                  <span className="badge--meta">
                    {moduleQuery.data.learning?.length || 0}{" "}
                    {i18n.language !== "en"
                      ? t("enrolmentView.learningItem")
                      : `Learning Item${moduleQuery.data.learning?.length === 1 ? "" : "s"}`}
                  </span>
                }
              /> */}
            </Space>

            {moduleQuery?.data?.currentState !== "completed" &&
            isModuleLocked(
              moduleQuery?.data,
              courseQuery?.data?.publication,
              courseQuery?.data?.activity,
              null,
              courseQuery?.data?.isLocked
            ) ? (
              <Tag color="orange" icon={<LockOutlined />}>
                {t("thisModuleIsLocked")}
              </Tag>
            ) : (
              <Button
                loading={loading}
                onClick={() =>
                  handleClick(
                    `/enrolments/${courseUid}/modules/${
                      moduleQuery?.data?.continue?.moduleUid || moduleQuery?.data?.uid
                    }/learning/${
                      moduleQuery?.data?.continue?.lessonUid || moduleQuery?.data?.learning?.[0]?.uid
                    }/courses`
                  )
                }
                type="primary"
              >
                {t("goToModule")}
              </Button>
            )}

            <Divider />
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={8}>
                <Card>
                  <Statistic
                    title={t("Lessons")}
                    value={moduleQuery?.data?.lessonCount}
                    valueStyle={{ color: "#7fba7a" }}
                    prefix={<BookOutlined />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card>
                  <Statistic
                    title={t("Assessments")}
                    value={moduleQuery?.data?.learning?.filter((x) => x.kind === "assessment")?.length || 0}
                    valueStyle={{ color: "#fec22d" }}
                    prefix={<ContainerOutlined />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card>
                  <Statistic
                    title={t("Assignments")}
                    value={moduleQuery?.data?.learning?.filter((x) => x.kind === "assignment")?.length || 0}
                    valueStyle={{ color: "#335191" }}
                    prefix={<CheckSquareOutlined />}
                  />
                </Card>
              </Col>
            </Row>
            <Divider />
            <Typography.Title level={3}>{t("Learning")}</Typography.Title>
            <ul className="learning--list">
              {moduleQuery.data.learning.map((item) => {
                const complete = moduleQuery?.data?.activity?.some(
                  (x) => x?.uid === item?.uid && (x.status === "completed" || x?.status === "submitted")
                )
                const failed = moduleQuery?.data?.activity?.some((x) => x?.uid === item?.uid && x.status === "failed")
                return (
                  <li key={item.uid} className={complete ? "completed" : failed ? "failed" : ""}>
                    <Typography.Text strong>{item?.title}</Typography.Text>
                    <Space>
                      <Tag
                        className="capitalize"
                        color={item.kind === "lesson" ? "green" : item.kind === "assessment" ? "blue" : "purple"}
                      >
                        {`${item.kind}${item?.kind !== "assessment" ? ":" : ""} ${item.type || ""}`}
                      </Tag>
                      {complete && (
                        <Tag icon={<CheckSquareOutlined />} color="green">
                          {t("Completed")}
                        </Tag>
                      )}
                      {failed && <Tag color="red">{t("Failed")}</Tag>}
                    </Space>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      ) : (
        <Spin />
      )}
    </Modal>
  )
}
